<template>
  <div class="card card-custom bg-light card-stretch gutter-b" v-if="error.status || items.length > 0">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">En popüler deneyimler</h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <!--begin::Item-->
      <div class="d-flex align-items-center mb-10" v-for="item in items" :key="item.id">
        <!--begin::Symbol-->
        <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
          <div class="symbol-label" :style="{ backgroundImage: `url(${item.image})` }"></div>
        </div>
        <!--end::Symbol-->
        <!--begin::Title-->
        <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
          <span class="text-dark-75 font-weight-bold font-size-lg">{{ item.name }}</span>
        </div>
        <!--end::Title-->
        <!--begin::Info-->
        <div class="d-flex align-items-center py-lg-0 py-2">
          <div class="d-flex flex-row align-items-center text-center">
            <i class="fa fa-star mr-2 text-warning font-size-lg"></i>
            <span class="text-dark-65 font-weight-bolder font-size-h4">{{ item.rating }}</span>
          </div>
        </div>
        <!--end::Info-->
      </div>
      <!--end::Item-->

      <!--begin::Error-->
      <div class="alert alert-primary" role="alert" v-if="error.status">
        {{ error.message }}
      </div>
      <!--end::Error-->

    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: "AttractionMostPopularExperiences",
  props: ["attractionId"],
  data() {
    return {
      items: [],
      error: {
        status: false,
        message: ''
      }
    }
  },
  methods: {
    getMostPopulars() {
      ApiService.get(`attraction/getMostPopularExperiences.req.php?id=${this.attractionId}`)
      .then(({ data }) => {
        this.items = data.experiences;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    }
  },
  mounted() {
    this.getMostPopulars();
  }
}
</script>

<style>

</style>