<template>
  <div
    class="card card-custom bg-light gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header h-auto border-0">
      <!--begin::Title-->
      <div class="card-title py-5">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder"
            >Son 6 Aylık Satışlar</span
          >
          <span class="d-block text-dark-50 mt-2 font-size-sm"
            >{{ attractionName }} destinasyonundaki etkinliklerin satış grafiği</span
          >
        </h3>
      </div>
      <!--end::Title-->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Chart-->
      <apexchart
        class="card-rounded-bottom"
        :options="chartOptions"
        :series="series"
        type="line"
        v-if="!error.status"
      ></apexchart>
      <!--end::Chart-->
      
      <!--begin::Error-->
      <div class="alert alert-primary" role="alert" v-else>
        {{ error.message }}
      </div>
      <!--end::Error-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from '@/core/services/api.service';
import moment from 'moment'

export default {
  name: "AttractionLastSixMonthSales",
  props: ["attractionId", "attractionName"],
  data() {
    return {
      chartOptions: {},
      series: [],
      xAxis: [],
      error: {
        status: false,
        message: ''
      }
    } 
  },
  methods: {
    getData() {
      ApiService.get(`attraction/getLastSixMonthSales.req.php?id=${this.attractionId}`)
      .then(({ data }) => {
        this.series = [
          {
            name: "Satış",
            type: "column",
            data: data.amount
          },
          {
            name: "Adet",
            type: "line",
            data: data.quantity
          }
        ];
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    moment.locale("tr");
    this.getData();
    this.chartOptions =  {
      chart: {
        type: "line",
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["30%"],
          endingShape: "rounded"
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
      },
      stroke:{
        width: [2 , 4]
      },
      xaxis: {
        categories: [
          moment().subtract(5, 'months').format('MMM'),
          moment().subtract(4, 'months').format('MMM'),
          moment().subtract(3, 'months').format('MMM'),
          moment().subtract(2, 'months').format('MMM'),
          moment().subtract(1, 'months').format('MMM'),
          moment().format('MMM')
        ],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      yaxis: [
        {
          labels: {
            formatter: function(val) {
              return val.toFixed(0);
            },
            style: {
              colors: this.layoutConfig("colors.gray.gray-500"),
              fontSize: "12px",
              fontFamily: this.layoutConfig("font-family")
            }
          }
        },
        {
          opposite: true,
          labels: {
            formatter: function(val) {
              return val.toFixed(0);
            },
            style: {
              colors: this.layoutConfig("colors.gray.gray-500"),
              fontSize: "12px",
              fontFamily: this.layoutConfig("font-family")
            }
          }
        }
      ],
      fill: {
        opacity: 1
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family")
        },
        y: [
          {
            formatter: function(val) {
              const formatter = new Intl.NumberFormat('tr-TR', {
                style: 'currency',
                currency: 'TRY',
              });
              return formatter.format(val);
            }
          },
          {
            formatter: function(val) {
              return val + " Adet";
            }
          }
        ] 
      },
      colors: [
        this.layoutConfig("colors.theme.base.success"),
        this.layoutConfig("colors.theme.base.warning")
      ],
      grid: {
        borderColor: this.layoutConfig("colors.gray.gray-200"),
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        }
      }
    }
  }
}
</script>

<style>

</style>