<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bold text-dark">
          Gezilecek Yer Bilgileri
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Gezilecek yer açıklamasını güncelleyin</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success font-weight-bold"
          ref="kt_save_changes"
          @click="setDetails()"
        >
          Değişiklikleri Kaydet
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="d-flex justify-content-center">
          <b-alert class="col-xl-6 mb-8" :show="alert.dismissCountDown" :variant="alert.variant" dismissible fade @dismissed="dismissAlert()" @dismiss-count-down="countDownChanged">{{ alert.message }}</b-alert>
        </div>
        
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Türkçe Bilgiler</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Başlık</label
          >
          <div class="col-lg-9 col-xl-8">
          <input
              ref="headerTR"
              class="form-control form-control-lg form-control-solid"
              type="text"
              :value="header.tr"
            />
            </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Açıklama</label
          >
          <div class="col-lg-9 col-xl-8">
            <ckeditor ref="descriptionTR" :editor="editor" :value="description.tr" v-model="editorData.tr" :config="editorConfig"></ckeditor>
          </div>
        </div>
        
        
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">İngilizce Bilgiler</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Başlık</label
          >
          <div class="col-lg-9 col-xl-8">
          <input
              ref="headerEN"
              class="form-control form-control-lg form-control-solid"
              type="text"
              :value="header.en"
            />
            </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Açıklama</label
          >
          <div class="col-lg-9 col-xl-8">
            <ckeditor ref="descriptionEN" :editor="editor" :value="description.en" v-model="editorData.en" :config="editorConfig"></ckeditor>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import Swal from "sweetalert2";

import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: "AttractionDetails",
  components: {
    ckeditor: CKEditor.component
  },
  props: ["attractionId"],
  data() {
    return {
      header: {
        tr: '',
        en: ''
      },
      description: {
        tr: '',
        en: ''
      },
      editor: ClassicEditor,
      editorData: {
        tr: '',
        en: ''
      },
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
        heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
            ]
        }
      },
      alert: {
        dismissCountDown: 0,
        variant: 'secondary',
        message: ''

      }
    };
  },
  methods: {
    // Get Destination Details
    getDetails() {
      ApiService.get(`attraction/getDetails.req.php?id=${this.attractionId}`)
      .then(({ data }) => {
        this.header.tr = data.header.tr;
        this.header.en = data.header.en;
        this.editorData.tr = data.description.tr;
        this.editorData.en = data.description.en;
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.error,
          text: response.data.errorMessage,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    },

    // Update Destination Details
    setDetails() {
      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      const data = {
        id: this.attractionId,
        description: JSON.stringify({
          tr: {
            header: this.$refs.headerTR.value,
            description: this.editorData.tr
          },
          en: {
            header: this.$refs.headerEN.value,
            description: this.editorData.en
          }
        })
      }
      ApiService.post('attraction/setDetails.req.php', data)
      .then(() => {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        this.showAlert('success', 'Değişiklikler kaydedildi!');
      })
      .catch(({ response }) => {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        this.showAlert('danger', response.data.errorMessage);
      });
    },

    // Show Alert
    showAlert(variant, message) {
      this.alert.variant = variant;
      this.alert.message = message;
      this.alert.dismissCountDown = 5;
    },

    // Dismiss Alert
    dismissAlert() {
      this.alert.dismissCountDown = 0;
      this.alert.message = '';
      this.alert.variant = 'secondary';
    },

    // Alert count down changed
    countDownChanged(dismissCountDown) {
      this.alert.dismissCountDown = dismissCountDown;
    },
      
  },
  mounted() {
    this.getDetails();
  }
}
</script>

<style>

</style>