<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="row">
          <div class="col-xl-4">
            <div class="card card-custom gutter-b" :class="'bg-'+status[attractionStatus].bg" style="height: 120px">
              <!--begin::Body-->
              <div class="card-body">
                <span class="svg-icon svg-icon-3x svg-icon-white ml-n2">
                  <inline-svg :src="`media/svg/icons/${status[attractionStatus].icon}`" />
                  <!--end::Svg Icon-->
                </span>
                <div class="text-inverse-success font-weight-bolder font-size-h3 mt-3">{{ status[attractionStatus].text }}</div>
              </div>
              <!--end::Body-->
            </div>
          </div>
          <div class="col-xl-4">
            <div class="card card-custom bg-info gutter-b" style="height: 120px;">
              <!--begin::Body-->
              <div class="card-body d-flex flex-column p-0" style="position: relative;">
                <!--begin::Stats-->
                <div class="flex-grow-1 card-spacer-x pt-6">
                  <div class="text-inverse-info font-weight-bold">Toplam Satış</div>
                  <div class="text-inverse-danger font-weight-bolder font-size-h3">{{ totalSale }}</div>
                </div>
                <!--end::Stats-->                
              </div>
              <!--end::Body-->
            </div>
          </div>
          <div class="col-xl-4">
            <div class="card card-custom bg-light gutter-b" style="height: 120px">
              <!--begin::Body-->
              <div class="card-body d-flex flex-column p-0" style="position: relative;">
                <!--begin::Stats-->
                <div class="flex-grow-1 card-spacer-x pt-6">
                  <div class="text-dark-50 font-weight-bold">{{ attractionName }} destinasyonunda</div>
                  <div class="font-weight-bolder font-size-h3">{{ numberOfExperiences }} Etkinlik</div>
                </div>
                <!--end::Stats-->
              <!--end::Body-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6">
        <MostPopularExperiences :attraction-id="attractionId"></MostPopularExperiences>
      </div>
      <div class="col-xl-6">
        <BestSellerExperiences :attraction-id="attractionId"></BestSellerExperiences>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <LastSixMonthSales :attraction-id="attractionId" :attraction-name="attractionName"></LastSixMonthSales>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import Swal from "sweetalert2";

import MostPopularExperiences from "@/view/pages/attraction/components/MostPopularExperiences";
import BestSellerExperiences from "@/view/pages/attraction/components/BestSellerExperiences";
import LastSixMonthSales from "@/view/pages/attraction/components/charts/LastSixMonthSales";

export default {
  name: "AttractionOverview",
  props: ["attractionId", "attractionName"],
  components: {
    MostPopularExperiences,
    BestSellerExperiences,
    LastSixMonthSales
  },
  data () {
    return {
      status: {
        "N/A": {
          bg: "light",
          text: "N/A",
          icon: ""
        },
        "0": {
          bg: "warning",
          text: "Yayında Değil",
          icon: "Code/Warning-1-circle.svg"
        },
        "1": {
          bg: "success",
          text: "Yayında",
          icon: "Devices/LTE2.svg"
        }
      },
      attractionStatus: 'N/A',
      totalSale: 'N/A',
      numberOfExperiences: 0
    }
  },
  mounted() {
    this.getOverview();
  },
  methods: {
    getOverview() {
      ApiService.get(`attraction/getOverview.req.php?id=${this.attractionId}`)
      .then(({ data }) => {
        this.attractionStatus = data.stats.status;
        this.totalSale = data.stats.totalSale; 
        this.numberOfExperiences = data.stats.numberOfExperiences;
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.error,
          text: response.data.errorMessage,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    }
  }
}
</script>

<style>

</style>